<template>
  <div class="py-16 pb-0">
    <div class="container mx-auto sm:mb-16">
      <h1 class="mb-16 mb-4 text-center font-merri text-2xl sm:text-4xl">
        Kittens
      </h1>
      <div>
        <div
          class="w-full px-4 text-left text-center sm:mx-auto sm:w-1/2 sm:px-0"
        >
          <p class="strong font-semibold">
            Giving kittens the best start in life
          </p>
          <p>
            Kittens have sensitive little stomachs so it’s particularly
            important to start them on a natural and healthy diet as early as
            possible. A high meat content, balanced diet with the optimum
            balance of protein, essential fats, essential nutrients and moisture
            will ensure your kitten can grow, develop and really thrive!
          </p>
          <p>
            In our kitten range we have 5 Wet Food options aswell as our Premium
            Dry Food, all specifically formulated for kittens. Try them all with
            our kitten Taster Box!

            <!-- We’ve developed our own range of kitten specific premium food. It’s
            especially formulated to provide young cats wit the balanced
            nutrition and increased energy required during growth and
            development...and all that playing! -->
          </p>
        </div>
      </div>
    </div>
    <div class="text-indigo-400">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          class="filler"
          d="M0,160L48,138.7C96,117,192,75,288,90.7C384,107,480,181,576,197.3C672,213,768,171,864,144C960,117,1056,107,1152,90.7C1248,75,1344,53,1392,42.7L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
    <div class="bg-indigo-400">
      <div
        class="container mx-auto flex flex-col overflow-hidden py-16 sm:overflow-visible sm:py-0"
      >
        <div class="flex flex-col sm:flex-row sm:self-end">
          <div
            class="mb-16 w-full p-0 sm:-mt-4 sm:mb-0 sm:ml-0 sm:w-1/2 sm:p-8"
          >
            <div class="relative rounded-lg bg-teal-200 p-6 py-10 pb-4 sm:p-16">
              <div class="relative z-10">
                <h3 class="mb-4 text-left font-merri text-lg">
                  Should I feed Wet or Dry?
                </h3>
                <p>
                  Every kitten has their own preference around the flavours and
                  texture they prefer, some like wet food, some like dry food or
                  both. We understand this and offer a Kitten taster pack so
                  they can find what they like in their own time!
                </p>
                <p>
                  Kittens (and cats) naturally struggle to take on enough water
                  and need a moisture-rich diet in a similar way to their
                  natural diet in the wild. Considering this, kittens should be
                  fed mostly wet food or wet and dry food and not solely dry
                  food wherever possible. Giving a mostly wet diet ensures your
                  kitten has a good daily water intake which is important for
                  maintaining a healthy urinary tract system and looking after
                  their long term kidney health.
                </p>
                <p>
                  Both our kitten and regular food has the optimum amount of
                  high meat content, moisture and nutrients they need as a
                  kitten and a growing cat.
                </p>
              </div>
              <div
                class="rotate-mini absolute inset-0 -ml-12 transform rounded-lg bg-white shadow-2xl sm:ml-0"
              ></div>
            </div>
          </div>
          <div class="mt-14 w-full p-0 sm:-mt-64 sm:w-2/5 sm:px-8">
            <div
              class="relative mt-14 rounded-lg bg-pink-300 p-8 py-10 pb-4 sm:-mt-4"
            >
              <div class="relative z-10">
                <h3 class="mb-4 text-left font-merri text-lg">
                  Grain Free Recipe
                </h3>
                <p>
                  In the wild, Cats wouldn’t naturally consume carbohydrates
                  such as rice or wheat as their stomachs have never evolved to
                  digest or make use of them! They are obligate carnivores.
                </p>
                <p>
                  Many kitten foods have a high proportion of carbohydrates or
                  sugar. All our wet kitten food has No Added Sugar, Grain Free
                  and full of natural protein and fats to keep kittens happy and
                  healthy from the start!
                </p>
              </div>
              <div
                class="rotate-mini-more absolute inset-0 -mr-12 transform rounded-lg bg-white p-4 shadow-2xl sm:mr-0"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="textColor" class="sm:-mt-32">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          class="filler"
          d="M0,192L60,181.3C120,171,240,149,360,154.7C480,160,600,192,720,208C840,224,960,224,1080,229.3C1200,235,1320,245,1380,250.7L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        ></path>
      </svg>
    </div>
    <div class="container mx-auto mt-8 sm:-mt-12 sm:mb-8">
      <div class="w-full px-4 text-left sm:mx-auto sm:w-1/2 sm:px-0">
        <h3 class="mb-8 text-center font-merri text-2xl">Where do I start?</h3>
        <p>
          We offer a Kitten Taster Box so your kittens can try all of our kitten
          food options and decide which they like best!
        </p>
      </div>
    </div>
    <div class="flex justify-center p-8 pt-0">
      <action-ctas taster-type="kitten" />
    </div>
  </div>
</template>

<script>
import ActionCtas from "@/components/ActionCtas";
import utils from "@/utils";
export default {
  metaInfo: {
    title: "Wet & Dry Kitten Food from The Cool Cat Club",
    meta: [],
  },
  components: {
    ActionCtas,
  },
  computed: {
    textColor() {
      return "text-indigo-400";
    },
    isValid() {
      return this.checkEmail(this.emailAddress);
    },
  },
  methods: {
    checkEmail: function (email) {
      if (email) {
        let regex = new RegExp(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        );
        return email.match(regex);
      }
    },
    saveEmail() {
      utils.setStorage("onML", true, "local");
      this.sendSuccess = true;
    },
  },
  data() {
    return {
      emailAddress: "",
      alreadySent:
        utils.getStorage("onML", "local").length !== 0
          ? utils.getStorage("onML", "local")
          : false,
      sendSuccess: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.filler {
  fill: currentColor;
}

.has-blue {
  color: #0b315e;
}

.rotate-mini {
  transform: rotate(-2deg);
}
.rotate-mini-more {
  transform: rotate(2deg);
}
</style>
